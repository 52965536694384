import React from "react";
import Event from "../Events/Event";
import Message from "../Message/Message";
import Slider from "../Slider/Slider";
import MissionAndVision from "../AboutUs/MissionAndVision/MissionAndVision";
import QacTeam from "../qac-team/QacTeam";
import Welcome from "../Welcome/Welcome";

const Home = () => {
  return (
    <>
      <Slider></Slider>
      <Welcome></Welcome>
      <Message></Message>
      <MissionAndVision></MissionAndVision>
      <Event></Event>
      <QacTeam></QacTeam>
    </>
  );
};

export default Home;
