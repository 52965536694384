import React from "react";
import "./Footer.css";
import { FaCheckDouble, FaHome } from "react-icons/fa";
import Facebook from "../../assets/facebook.png";
import Linkend from "../../assets/linkend.png";
import Twiter from "../../assets/twiter.png";
import Instagram from "../../assets/instagram.webp";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer-container">
        <div className="footer-body">
          <div className="footer-content">
            <span className="heading">About Us</span>
            <div className="item-f">
              <ul>
                <NavLink to="/vision-mission">
                  <li>
                    <FaCheckDouble></FaCheckDouble>Vision & Mission
                  </li>
                </NavLink>

                <NavLink to="/objectives">
                  <li>
                    <FaCheckDouble></FaCheckDouble>Objectives
                  </li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <span className="heading">Important Links</span>
            <div className="item-f">
              <ul>
                <a
                  href="https://www.isu.ac.bd/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>
                    <FaCheckDouble></FaCheckDouble>ISU
                  </li>
                </a>
                <a href="https://ugc.gov.bd/" target="_blank" rel="noreferrer">
                  <li>
                    <FaCheckDouble></FaCheckDouble>UGC
                  </li>
                </a>

                <li>
                  <FaCheckDouble></FaCheckDouble>HEQEP
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <span className="heading">Contact Us</span>
            <div className="item-f">
              <ul>
                <li>
                  <FaCheckDouble></FaCheckDouble>IQAC (4th Floor)
                </li>
                <li>
                  <FaCheckDouble></FaCheckDouble>International Standard
                  University
                </li>
                <li>
                  <FaHome></FaHome>69, Mohakhali C/A, Dhaka-1212
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <span className="heading">Social Media</span>
            <div className="item-f">
              <div className="social-icon">
                <a
                  href="https://www.facebook.com/ISUBD/"
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="face-book" />
                </a>
                <a
                  href="https://twitter.com/ISU_BD"
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer"
                >
                  <img src={Twiter} alt="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/in/isubd/"
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer"
                >
                  <img src={Linkend} alt="link-end" />
                </a>
                <a
                  href="https://www.instagram.com/isubangladesh/"
                  target="_blank"
                  className="social-icon"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          © {currentYear} All right reserved. International Standard University
          (ISU)
        </div>
      </div>
    </>
  );
};

export default Footer;
