import React from "react";
import "./IQACTeam.css";
import QacTeam from "../qac-team/QacTeam";

import { qacTeam } from "../../data";

const QAC = () => {
  return (
    <>
      <div className="team-body">
        <div className="details-page-sub_heading">
          <h4>QAC Committee</h4>
        </div>
        <div className="team-content">
          <div className="team-heading">
            <h4>Quality Assurance Committee (QAC)</h4>
            <p>
              As per the requirement of Quality Assurance Unit of the UGC, BNQF
              and the BAC following committees for ensuring the overall quality
              assurance at ISU have been formed and approval by the ISU
              authority.
            </p>
          </div>
          <hr />
          <div className="qac-table">
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="45%">Name</th>
                <th width="40%">Membership</th>
                <th width="10%">Image</th>
              </tr>
              {qacTeam?.map((member, index) => (
                <tr key={index}>
                  <td>{member.id}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {member.name},
                    </span>
                    <br />
                    {member.designation}, {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default QAC;
