import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";
import { sliderData } from "../../data/sliderData";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { motion } from "framer-motion";

const Slider = ({ interval = 6000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderInterval = useRef(null);
  const sDataLength = sliderData.length;

  const prev = () => {
    stopSlideTimer();
    setCurrentSlide((prevSlide) =>
      prevSlide > 0 ? prevSlide - 1 : sDataLength - 1
    );
  };

  const next = () => {
    stopSlideTimer();
    setCurrentSlide((prevSlide) =>
      prevSlide < sDataLength - 1 ? prevSlide + 1 : 0
    );
  };

  const startSlideTimer = () => {
    stopSlideTimer();
    sliderInterval.current = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide < sDataLength - 1 ? prevSlide + 1 : 0
      );
    }, interval);
  };

  const stopSlideTimer = () => {
    if (sliderInterval.current) {
      clearInterval(sliderInterval.current);
    }
  };

  const switchIndex = (index) => {
    stopSlideTimer();
    setCurrentSlide(index);
    startSlideTimer();
  };

  useEffect(() => {
    startSlideTimer();
    return () => stopSlideTimer();
  }, [interval]);

  const sliderVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: {
      opacity: 1,
      transition: { type: "tween", duration: 2, ease: "easeIn" },
    },
    exit: {
      opacity: 0,
      transition: { type: "tween", duration: 2, ease: "easeOut" },
    },
  };

  return (
    <div className="slider-container">
      <div className="slider-image">
        <motion.img
          key={currentSlide}
          src={sliderData[currentSlide].image}
          alt={`Slide ${currentSlide}`}
          onMouseOver={stopSlideTimer}
          onMouseLeave={startSlideTimer}
          variants={sliderVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        />
      </div>

      <div className="arrows">
        <div onClick={prev}>
          <FaArrowAltCircleLeft />
        </div>
        <div onClick={next}>
          <FaArrowAltCircleRight />
        </div>
      </div>

      <div className="dot">
        {sliderData.map((_, index) => (
          <button
            key={index}
            className={`carousel-indicator-item${
              currentSlide === index ? " active" : ""
            }`}
            onClick={() => switchIndex(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
