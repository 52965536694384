import React from "react";
import { NavLink } from "react-router-dom";
import "./SubMenu.css";

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";

const dropdownVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 3,
      ease: "easeIn",
    },
  },
  exit: {
    x: "-100vw",
    transition: {
      easeout: "easeOut",
      duration: 3,
    },
  },
};

const SubMenu = ({ submenus, dropdown }) => {
  const [responsive, setResponsive] = useState("dropdown");
  const windowWidth = window.innerWidth;

  useEffect(() => {
    if (windowWidth < 998) {
      setResponsive("dropdown_mobile");
    }
  }, [windowWidth]);
  return (
    <AnimatePresence>
      <motion.ul
        className={`${responsive} ${dropdown ? "show" : ""}`}
        variants={dropdownVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {submenus.map((submenu, index) => (
          <>
            <motion.li
              className="sub-menu-items"
              key={index}
              whileHover={{ scale: 1.1, color: "yellow", originX: 0 }}
              transition={{ type: "spring", stiffness: 200 }}
            >
              <NavLink to={submenu.path}>{submenu.name}</NavLink>
            </motion.li>
            <hr />
          </>
        ))}
      </motion.ul>
    </AnimatePresence>
  );
};

export default SubMenu;
