import React from "react";
import "./IQACTeam.css";
import QacTeam from "../qac-team/QacTeam";
import { psacAMM, psacCSE, psacDBA, psacEnglish, psacTE } from "../../data";

const PSAC = () => {
  return (
    <>
      <div className="team-body">
        <div className="details-page-sub_heading">
          <h4>PSAC Committee</h4>
        </div>
        <div className="team-content">
          <div className="team-heading">
            <h4>Program self Assessment committee (PSAC)</h4>
          </div>
          <hr />
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Business Administration</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              {psacDBA?.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {member.name}
                  </td>
                  <td>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {member.designation},
                    </span>
                    {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Computer Science & Engineering</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              {psacCSE?.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {member.name}
                  </td>
                  <td>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {member.designation},
                    </span>
                    {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of English</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>

              {psacEnglish?.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {member.name}
                  </td>
                  <td>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {member.designation},
                    </span>
                    {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Textile Engineering</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              {psacTE?.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {member.name}
                  </td>
                  <td>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {member.designation},
                    </span>
                    {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Apparel Merchandising and Management</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              {psacAMM?.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                    {member.name}
                  </td>
                  <td>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {member.designation},
                    </span>
                    {member.institution}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>{member.memberShip}</td>
                  <td>
                    <img
                      src={member.image}
                      alt={member.name}
                      width={100}
                      height={100}
                    />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default PSAC;
