import React from "react";
import "./QacTeam.css";
import image1 from "../../assets/ekramul.jpg";
import { motion } from "framer-motion";
import image2 from "../../assets/masum.png";
import image3 from "../../assets/taufiq.jpg";
import image4 from "../../assets/md-kabir-hossain.jpg";

const strokeMotionVariants = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  visible: {
    opacity: 1,
    width: "5rem",
    transition: {
      type: "tween",
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};

const headingVariants = {
  hidden: {
    opacity: 0,
    x: "-30px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      delay: 0.5,
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const QacTeam = () => {
  return (
    <>
      <div className="qac-body">
        <div className="qac-content">
          <div>
            <div className="heading">
              <motion.div
                className="title_stroke"
                variants={strokeMotionVariants}
                initial="hidden"
                whileInView="visible"
              ></motion.div>
              <motion.span
                className="heading"
                variants={headingVariants}
                initial="hidden"
                whileInView="visible"
              >
                IQAC Team
              </motion.span>
            </div>
          </div>
          <div className="qac-team">
            <div className="profile-content">
              <div className="qac-profile-image">
                <img src={image1} alt="qac-profile" />
              </div>
              <div className="details">
                <h4>Professor Dr. Md. Ekramul Hoque</h4>
                <p>Professor, Department of Business Administration</p>
                <p>International Standard University</p>
                <h4>Director, QAC</h4>
              </div>
            </div>
            <div className="profile-content">
              <div className="qac-profile-image">
                <img src={image2} alt="qac-profile" />
              </div>
              <div className="details">
                <h4>Dr. Monirul Hasan Masum</h4>
                <p>
                  Associate Professor, Department of Business Administration
                </p>
                <p>International Standard University</p>
                <h4>Additional Director, QAC</h4>
              </div>
            </div>
            <div className="profile-content">
              <div className="qac-profile-image">
                <img src={image3} alt="qac-profile" />
              </div>
              <div className="details">
                <h4>Md. Taufiqul Islam</h4>
                <p>Sr. Administrative Officer (PS to VC)</p>
                <p>International Standard University</p>
                <h4>Senior Officer, QAC</h4>
              </div>
            </div>
            <div className="profile-content">
              <div className="qac-profile-image">
                <img src={image4} alt="qac-profile" />
              </div>
              <div className="details">
                <h4>Md. Kabir Hossain</h4>
                <p>Senior Accounts Officer</p>
                <p>International Standard University</p>
                <h4>Office Assistant, QAC</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QacTeam;
