import image1 from "../assets/slider1.JPG";
import image2 from "../assets/slider2.jpg";
import image3 from "../assets/slider3.JPG";
import image4 from "../assets/slider4.jpeg";

export const sliderData = [
  {
    image: image2,
  },
  {
    image: image1,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
];
