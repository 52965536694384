import Dr_Abdul_Awal_Khan from "../assets/professor-dr-abdul-awal-khan.png";
import Dr_Md_Abul_Kashem from "../assets/kashem.jpg";
import ekramul from "../assets/ekramul.jpg";
import saif from "../assets/Dr.Khandoker-Saif-Uddin-1.jpg";
import mustafizur from "../assets/syed-mustafizur-rahman-chowdhury.jpg";
import Kamruzzaman from "../assets/Dr. Muhammad Kamruzzaman.jpg";
import faizullah_kawshik from "../assets/faizullah-kawshik.jpg";
import ataur from "../assets/ataur-rahman-bhuiyan.jpg";
import masum from "../assets/masum.png";
import monjur_morshed from "../assets/monjur_morshed.jpg";
import jaglul_mridha from "../assets/jaglul_mridha.jpg";
import abdul_based_miah from "../assets/engr-md-abdul-based-miah.png";
import mushfika from "../assets/mushfika-rahman.jpg";
import mohammad_ali from "../assets/mohammad-ali.jpg";
import mahbubur from "../assets/md-mahbubur-rahman.png";
import naser_iqbal from "../assets/s.m-naser-iqbal.jpg";
import mujibur from "../assets/md-mujibur-rahman-majumder.jpg";
import taspia from "../assets/taspia-salam.jpg";
import snigdhya from "../assets/snigdha.jpg";
import mamun from "../assets/abdullah_al_mamun.jpg";
import shamima from "../assets/shamima-siddika.png";
import musa_ali_reza from "../assets/musa_ali_reza.jpg";
import sobuj from "../assets/sobuj.jpg";
import zahid from "../assets/zahid_hasan.jpg";

export const qacTeam = [
  {
    id: 1,
    name: "Prof. Dr. Abdul Awal Khan",
    designation: "Vice Chancellor",
    institution: "International Standard University",
    memberShip: "Chair",
    image: Dr_Abdul_Awal_Khan,
  },
  {
    id: 2,
    name: "Prof. Dr. Monjur Morshed Mahmud",
    designation: "Dean, Faculty of Business Studies",
    institution: "International Standard University",
    memberShip: "Member",
    image: monjur_morshed,
  },
  {
    id: 3,
    name: "Prof. Dr. Md. Abul Kashem",
    designation: "Dean, Faculty of Engineering & Technology	",
    institution: "International Standard University",
    memberShip: "Member",
    image: Dr_Md_Abul_Kashem,
  },
  {
    id: 4,
    name: "Prof. Dr. Md. Ekramul Hoque",
    designation:
      "Director, IQAC & Professor, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Director",
    image: ekramul,
  },
  {
    id: 5,
    name: "Prof. Dr. Khandoker Saif Uddin",
    designation: "Director, IQAC",
    institution:
      "International University of Business Agriculture and Technology (IUBAT)",
    memberShip: "Member",
    image: saif,
  },
  {
    id: 6,
    name: "Syed Mustafizur Rahman Chowdhury",
    designation:
      "Associate Professor & Chairperson, Department of Computer Science & Engineering ",
    institution: "International Standard University",
    memberShip: "Member",
    image: mustafizur,
  },
  {
    id: 7,
    name: "Dr. Muhammad Kamruzzaman	",
    designation:
      "Associate Professor & Chairperson, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Member",
    image: Kamruzzaman,
  },
  {
    id: 8,
    name: "Mohammad Faizullah Kawshik",
    designation: "Registrar",
    institution: "International Standard University",
    memberShip: "Member",
    image: faizullah_kawshik,
  },
  {
    id: 9,
    name: "A.R.M Ataur Rahman Bhuiyan",
    designation: "Director, Finance & Accounts (In-Charge)",
    institution: "International Standard University",
    memberShip: "Member",
    image: ataur,
  },
  {
    id: 10,
    name: "Dr. Monirul Hasan Masum",
    designation:
      "Additional Director & Associate Professor, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Member Secretary",
    image: masum,
  },
];

export const fqacFBS = [
  {
    id: 1,
    name: "Prof. Dr. Monjur Morshed Mahmud",
    designation: "Dean, Faculty of Business Studies",
    institution: "International Standard University",
    memberShip: "Chair",
    image: monjur_morshed,
  },
  {
    id: 2,
    name: "Professor Dr. Abdul Awal Khan",
    designation:
      "Vice-Chancellor & Dean, Faculty of Humanities and Social Sciences",
    institution: "International Standard University",
    memberShip: "Member",
    image: Dr_Abdul_Awal_Khan,
  },
  {
    id: 3,
    name: "Prof. Dr. Md. Abul Kashem",
    designation: "Dean, Faculty of Engineering & Technology",
    institution: "International Standard University",
    memberShip: "Member",
    image: Dr_Md_Abul_Kashem,
  },
  {
    id: 4,
    name: "Dr. Muhammad Kamruzzaman",
    designation: "Chairperson, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Member",
    image: Kamruzzaman,
  },
];

export const fqacFET = [
  {
    id: 1,
    name: "Prof. Dr. Md. Abul Kashem",
    designation: "Dean, Faculty of Engineering & Technology",
    institution: "International Standard University",
    memberShip: "Chair",
    image: Dr_Md_Abul_Kashem,
  },
  {
    id: 2,
    name: "Professor Dr. Abdul Awal Khan",
    designation:
      "Vice-Chancellor & Dean, Faculty of Humanities and Social Sciences",
    institution: "International Standard University",
    memberShip: "Member",
    image: Dr_Abdul_Awal_Khan,
  },
  {
    id: 3,
    name: "Prof. Dr. Monjur Morshed Mahmud",
    designation: "Dean, Faculty of Business Studies",
    institution: "International Standard University",
    memberShip: "Member",
    image: monjur_morshed,
  },

  {
    id: 4,
    name: "Prof. Engr. Md. Abdul Based Miah",
    designation: "Chairperson, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: abdul_based_miah,
  },
  {
    id: 5,
    name: "Syed Mustafizur Rahman Chowdhury",
    designation:
      "Associate Professor & Chairperson, Department of Computer Science & Engineering ",
    institution: "International Standard University",
    memberShip: "Member",
    image: mustafizur,
  },
  {
    id: 6,
    name: "Jaglul Haque Mridha",
    designation:
      "Assistant Professor & Chairperson, Department of Apparel Merchandising and Management",
    institution: "International Standard University",
    memberShip: "Member",
    image: jaglul_mridha,
  },
];

export const fqacFHSS = [
  {
    id: 1,
    name: "Professor Dr. Abdul Awal Khan",
    designation:
      "Vice-Chancellor & Dean, Faculty of Humanities and Social Sciences",
    institution: "International Standard University",
    memberShip: "Chair",
    image: Dr_Abdul_Awal_Khan,
  },
  {
    id: 2,
    name: "Prof. Dr. Monjur Morshed Mahmud",
    designation: "Dean, Faculty of Business Studies",
    institution: "International Standard University",
    memberShip: "Member",
    image: monjur_morshed,
  },

  {
    id: 3,
    name: "Prof. Dr. Md. Abul Kashem",
    designation: "Dean, Faculty of Engineering & Technology",
    institution: "International Standard University",
    memberShip: "Member",
    image: Dr_Md_Abul_Kashem,
  },
  {
    id: 4,
    name: "Sultana Musfika Rahman",
    designation:
      "Senior Lecturer & Chairperson(In-Charge), Department of English",
    institution: "International Standard University",
    memberShip: "Member",
    image: mushfika,
  },
];

export const psacDBA = [
  {
    id: 1,
    name: "Prof. Mohammad Ali",
    designation: "Professor, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Chair",
    image: mohammad_ali,
  },
  {
    id: 2,
    name: "Md. Mahbubur Rahman",
    designation: "Associate Professor, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Member",
    image: mahbubur,
  },

  {
    id: 3,
    name: "S. M. Naser Iqbal",
    designation: "Assistant Professor, Department of Business Administration",
    institution: "International Standard University",
    memberShip: "Member",
    image: naser_iqbal,
  },
];

export const psacCSE = [
  {
    id: 1,
    name: "Syed Mustafizur Rahman Chowdhury",
    designation:
      "Associate Professor & Chairperson, Department of Computer Science & Engineering",
    institution: "International Standard University",
    memberShip: "Chair",
    image: mustafizur,
  },
  {
    id: 2,
    name: "Md. Mujibur Rahman Majumder",
    designation:
      "Assistant Professor, Department of Computer Science & Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: mujibur,
  },

  {
    id: 3,
    name: "Taspia Salam",
    designation:
      "Senior Lecturer, Department of Computer Science & Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: taspia,
  },
];

export const psacEnglish = [
  {
    id: 1,
    name: "Sultana Musfika Rahman",
    designation:
      "Senior Lecturer & Chairperson(In-Charge), Department of English",
    institution: "International Standard University",
    memberShip: "Chair",
    image: mushfika,
  },
  {
    id: 2,
    name: "Snigdhya Hussain",
    designation: "Senior Lecturer, Department of English",
    institution: "International Standard University",
    memberShip: "Member",
    image: snigdhya,
  },

  {
    id: 3,
    name: "Abdullah Al Mamun",
    designation: "Senior Lecturer, Department of English",
    institution: "International Standard University",
    memberShip: "Member",
    image: mamun,
  },
];

export const psacTE = [
  {
    id: 1,
    name: "Prof. Engr. Md. Abdul Based Miah",
    designation: "Chairperson, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Chair",
    image: abdul_based_miah,
  },
  {
    id: 2,
    name: "Shamima Siddika",
    designation: "Assistant Professor, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: shamima,
  },

  {
    id: 3,
    name: "Md. Musa Ali Reza",
    designation: "Senior Lecturer, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: musa_ali_reza,
  },
];

export const psacAMM = [
  {
    id: 1,
    name: "Jaglul Haque Mridha",
    designation:
      "Assistant Professor & Chairperson, Department of Apparel Merchandising and Management",
    institution: "International Standard University",
    memberShip: "Chair",
    image: jaglul_mridha,
  },
  {
    id: 2,
    name: "Md. Sobuj",
    designation: "Senior Lecturer, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: sobuj,
  },

  {
    id: 3,
    name: "Md. Zahid Hasan",
    designation: "Lecturer, Department of Textile Engineering",
    institution: "International Standard University",
    memberShip: "Member",
    image: zahid,
  },
];
