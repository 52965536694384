import React from "react";
import "./Error.css";

const Error = () => {
  return (
    <>
      <h1>Error !! 404 Not Found</h1>
    </>
  );
};

export default Error;
